import network from "@/api/network";

export default class Content {
  static async getMenu() {
    return await network.get("content/menu", null, {});
  }

  static async getSteps() {
    return await network.get("content/steps", null, {});
  }

  static async getGifts() {
    return await network.get("content/gifts", null, {});
  }

  static async getRules() {
    return await network.get("content/rules", null, {});
  }

  static async getFAQ() {
    return await network.get("content/faq", null, {});
  }

  static async getInfo() {
    return await network.get("content/info", null, {});
  }

  static async getBrandLinks() {
    return await network.get("content/docs/brands", null, {});
  }

  static async sendFeedback(data) {
    return await network.post("content/feedback", data, {});
  }

  static async getNews() {
    return await network.get("content/news", null, {});
  }

  static async getNewsDetail(id) {
    return await network.get(`content/news/${id}`, null, {});
  }

  static async getPromos(page, perPage) {
    return await network.get(
      `actions/list/filter?page=${page}&per_page=${perPage}&is_enabled=1&sort_order_by_column=id&sort_direction=desc`,
      null,
      {}
    );
  }

  static async getPromoDetail(id) {
    return await network.get(`actions/list/filter?id=${id}`, null, {});
  }
}
